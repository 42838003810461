import LanguageMenu from '@/components/filter-sort/LanguageMenu';
import SortMenu from '@/components/filter-sort/SortMenu';
import {useWeek} from '@/context/WeekContext';
import {usePrintout} from '@/context/PrintoutContext';


const FilterSortBar = () => {

    const {currentWeekId, currentWeekStartDate, setSelectedWeekStartDate, isCurrentWeek, setIsCurrentWeek, setSelectedWeekId} = useWeek();
    const {printouts, setPrintouts} = usePrintout();

    const handleBackToCurrent = () => {
        setSelectedWeekId(currentWeekId);
        setSelectedWeekStartDate(currentWeekStartDate);
        setIsCurrentWeek(true);
    }

    return (
        <>
            <section className="filter-block">
                <div className="container">
                    <div className="row" id="translator_sec">
                        <div className={`col-md-6 col-lg-${!(isCurrentWeek) ? 4 : 6}`}>
                            <LanguageMenu />
                        </div>
                        {!isCurrentWeek &&
                            <div className='col-md-6 col-lg-3 button-week'>
                                <div role="button" onClick={handleBackToCurrent}>
                                    Back to this week's parsha
                                </div>
                            </div>
                        }
                        <div className={`col-md-6 col-lg-${!(isCurrentWeek) ? 5 : 6}`}>
                            <SortMenu
                                printouts={printouts}
                                setPrintouts={setPrintouts}
                            />
                        </div>

                        <div className="col-md-12">
                            <div className="search-container" hidden={true}>
                                <input
                                    type="text"
                                    className="search"
                                    placeholder="Search"
                                    name="search"
//                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FilterSortBar;
