import {useState} from 'react';
import getConfig from 'next/config';
import Head from "next/head";
import Layout from '@/components/Layout';
import SearchBannerArea from '@/components/search/SearchBannerArea';
import FilterSortBar from '@/components/filter-sort/FilterSortBar';
import WeekSelector from '@/components/WeekSelector';
import AdsContainer from '@/components/AdsContainer';
import PublicationsBlock from '@/components/PublicationsBlock';
import WeeklyPrintoutBlock from '@/components/WeeklyPrintoutBlock';
import {getSession} from 'next-auth/react';
import UserFollowingBlock from '@/components/UserFollowingBlock';

const { publicRuntimeConfig } = getConfig();

export default function Homepage({session}) {
    const currentURL = publicRuntimeConfig.BASE_URL;
    let pdfMetaTitle = "Weekly Torah Parsha Insights";

    const [ogData, setOgData] = useState({
        currentURL,
        pageTitle: pdfMetaTitle,
        pageDescription: 'Torah content ready to print',
    });

    const [ printoutsLoading, setPrintoutsLoading ] = useState(true);



    return (
        <>
            <Layout home ogData={ogData}>

                {/*Header*/}
                <Head>
                    <meta
                        name="description"
                        content=
                            "Explore our weekly Torah printouts, offering insightful commentary, detailed explanations, and beautifully formatted texts to enhance your study and understanding in different languages. Login/register to bookmark printouts, follow publications and subscribe to weekly newsletter mails."
                    />

                    <title>Weekly Torah Parsha Insights</title>
                </Head>

                {/*SearchBanner*/}
                <SearchBannerArea/>

                {/*Language & Sort*/}
                <FilterSortBar
                    setPrintoutsLoading={setPrintoutsLoading}
                />

                <section className="pt-5 pb-5">
                    <div className="container">

                        {/*Week Selector*/}
                        <WeekSelector
                            setPrintoutsLoading={setPrintoutsLoading}
                        />
                        <hr/>

                        {/*Following Block*/}
                        <UserFollowingBlock
                            printoutsLoading={printoutsLoading}
                        />

                        {/*Ads Container*/}
                        <AdsContainer/>

                        {/*Weekly Printout Block*/}
                        <WeeklyPrintoutBlock
                            setPrintoutsLoading={setPrintoutsLoading}
                            printoutsLoading={printoutsLoading}
                        />

                        {/*PublicationsBlock Block*/}
                        <PublicationsBlock />
                    </div>
                </section>
            </Layout>
        </>
    );
}


export async function getServerSideProps(context) {
    const session = await getSession({ req: context.req });

    return {
        props: {
            session
        },
    };
}
