import React, {useContext, useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AppContext} from '@/context/AppContext';
import {useFollowing} from '@/context/FollowingContext';
import {useSession} from 'next-auth/react';
import SkeletonCard from '@/components/skeletons/SkeletonCard';
import PrintoutSkeletonCard from '@/components/skeletons/PrintoutSkeletonCard';
import {useBookmark} from '@/context/BookmarkContext';
import dynamic from 'next/dynamic';
import {useWeek} from '@/context/WeekContext';
import {usePrintout} from '@/context/PrintoutContext';

const PrintoutCard = dynamic( () => import('@/components/partial/printoutCard'), { ssr: false } );


const UserFollowingBlock = ({printoutsLoading}) => {

    const {data: session} = useSession();

    const {
        handlePrint,
        currentAlgoliaQuery
    } = useContext(AppContext);

    const {
        followedPublications,
        filteredFollowingPrintoutCount,
        setFilteredFollowingPrintoutCount,
    } = useFollowing();

    const {printouts} = usePrintout();

    const {selectedWeekId, selectedWeekStartDate} = useWeek();

    const { bookmarkedPrintouts } = useBookmark();

    const [followingPrintouts, setFollowingPrintouts] = useState([]);
    const [showAllFollowing, setShowAllFollowing] = useState(false);

    useEffect(() => {

        if (followedPublications.length > 0) {
            const filteredFollowingPrintouts = printouts.filter((printout) => {
                return followedPublications.includes(printout.publication.id);
            });

            setFilteredFollowingPrintoutCount(filteredFollowingPrintouts.length);
            setFollowingPrintouts(filteredFollowingPrintouts);
        }
    }, [printouts, selectedWeekId, selectedWeekStartDate, followedPublications, filteredFollowingPrintoutCount]);

    const showAllData = () => {
        setShowAllFollowing(true);
    }


    return (
        <>
            {
                !currentAlgoliaQuery && session && filteredFollowingPrintoutCount > 0 &&
                 <>
                    <Row className="following-block">
                        <Col>
                            <div className="section-title">Followings</div>
                        </Col>
                    </Row>

                    <div className="printthumb_outer home_printthumb_outer printout-thumbnails">
                        <div id="thumbnails" className="printouts_block_outer">
                            <div className='row row-cols-2 row-cols-md-4 row-cols-lg-4'>
                                {
                                    printoutsLoading ? (
                                        <SkeletonCard
                                            length={2}
                                            Component={PrintoutSkeletonCard}
                                        />
                                    ) : followingPrintouts.map((printout, index) => (
                                        <PrintoutCard
                                            key={printout.id}
                                            printout={printout}
                                            handlePrint={handlePrint}
                                            showWeekName={false}
                                            isHomePage={true}
                                            isBookmarkPage={false}
                                            isPublicationPage={false}
                                            homePageFollowings={true}
                                            bookmarkedPrints={bookmarkedPrintouts}
                                            followedPubs={followedPublications}
                                        />
                                    ))
                                }
                                <iframe id={"pdf-frame"} hidden={true}></iframe>
                            </div>
                        </div>
                    </div>
                    {
                        !showAllFollowing &&
                        filteredFollowingPrintoutCount > followingPrintouts.length && (
                            <div className="show-more text-center mt-5">
                                <p
                                    id="showFollowing"
                                    role="button"
                                    className="text-purple"
                                    onClick={showAllData}
                                >
                                    Show all ({filteredFollowingPrintoutCount})
                                </p>
                            </div>
                        )
                    }
                    <hr/>
                </>
            }
        </>
    );
}

export default UserFollowingBlock;
