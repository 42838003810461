import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dynamic from 'next/dynamic';
//import SearchComponent from '@/pages/algolia';

function SearchBannerArea() {
    const Autocomplete = dynamic(() =>
        import('@/components/search/Autocomplete')
    );

//    const [currentAlgoliaQuery, setCurrentAlgoliaQuery] = useState('');

    return (
        <section className="search_block">
            <Container>
                <Row>
                    <Col>

                        <div className='title_block center'>
                            <h1>Weekly Torah Parsha Insights</h1>
                            <p>5,000+ Printouts, 250+ Publications </p>
                            {/*<SearchComponent />*/}
                            {/*<Autocomplete />*/}
                        </div>

                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default SearchBannerArea;
