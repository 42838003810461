import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';
import { getLanguagesData } from '@/lib/api';
import { AppContext } from '@/context/AppContext';


const LanguageMenu = () => {
    const { selectedLanguages, setSelectedLanguages, isLanguageMenuOpened, setIsLanguageMenuOpened } = useContext(AppContext);

    const [languages, setLanguages] = useState([]);
    const languageMenuRef = useRef(null);
    const [languageFilterName, setLanguageFilterName] = useState('Language');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getLanguagesData();
                setLanguages(response);

                const selectedLanguageNames = response
                    .filter(language => selectedLanguages.includes(language.id))
                    .map(language => language.name);

                let languageLabelName;
                if (selectedLanguageNames.length === 0) {
                    languageLabelName = 'Language';
                } else if (selectedLanguageNames.length <= 3) {
                    languageLabelName = selectedLanguageNames.join(', ');
                } else {
                    languageLabelName = `${selectedLanguageNames.slice(0, 3).join(', ')}...`;
                }

                setLanguageFilterName(languageLabelName);
            } catch (error) {
                console.error('Failed to fetch languages:', error);
            }
        };

        fetchData().then();
    }, [selectedLanguages]);

    useEffect(() => {
        if (isLanguageMenuOpened) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isLanguageMenuOpened]);

    const handleClickOutside = (event) => {
        if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
            setIsLanguageMenuOpened(false);
        }
    };

    const toggleLanguageMenu = () => {
        setIsLanguageMenuOpened(!isLanguageMenuOpened);
    };

    const handleSelectAll = () => {
        if (selectedLanguages.length === languages.length) {
            setSelectedLanguages([]);
        } else {
            setSelectedLanguages(languages.map(language => language.id));
        }
    };

    const handleSelectLanguage = (id) => {
        if (selectedLanguages.includes(id)) {
            setSelectedLanguages(selectedLanguages.filter((languageId) => languageId !== id));
        } else {
            setSelectedLanguages([...selectedLanguages, id]);
        }
    };

    return (
        <div className="language-filter" ref={languageMenuRef}>
            <label>
                Filter by:{' '}
                <span onClick={toggleLanguageMenu}>{languageFilterName}</span>
            </label>
            {isLanguageMenuOpened && (
                <ul className="language-menu language-menu-desktop">
                    <li className={`tab-heading-01 ${selectedLanguages.length === languages.length ? 'active' : ''}`} onClick={handleSelectAll}>
                        {selectedLanguages.length === languages.length
                            ? <FaCheckSquare className="filterchecked" />
                            : <FaRegSquare className="filterunchecked" />} All
                    </li>
                    {languages.map((language) => (
                        <li key={language.id} className={`tab-heading-01 ${selectedLanguages.includes(language.id) ? 'active' : ''}`} onClick={() => handleSelectLanguage(language.id)}>
                            {selectedLanguages.includes(language.id)
                                ? <FaCheckSquare className="filterchecked" />
                                : <FaRegSquare className="filterunchecked" />}
                            {language.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default LanguageMenu;

