import React, { useState, useEffect } from 'react'
import Skeleton from "react-loading-skeleton";

const PublicationSkeletonCard = () => {
    const [screen, setScreen] = useState(1400);
    useEffect(() => {
        setScreen(window.innerWidth)
    }, [screen]);

    let height = 115;

    if( screen <= 350){
        height = 75
    } else if( screen <= 375 && screen > 350){
        height = 82
    } else if( screen <= 420 && screen > 375){
        height = 90
    }


    return (
        <div className="col">
            <div className="wrap_img">
                <Skeleton  height={height} />
            </div>
            <div className="pt-2">
                <Skeleton width={`100%`}/>
            </div>
        </div>
    );
};
export default PublicationSkeletonCard;
